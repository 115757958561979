import { Profile } from '../types/CandidateProfile';
import { SearchRequest, SearchResponse } from '../types/search';
import { getInstance } from './fetch';

export const getProfile = async () => {
    const { data } = await getInstance().get<{ data: Profile; error: string }>(`/seeker/profile`);
    return data;
};

export const doSearch = async (searchCriteria: SearchRequest) => {
    const { searchBy = 'employer' } = searchCriteria;
    if (searchBy === 'admin') {
        const { data } = await getInstance().post<{ data: SearchResponse; error: string }, any>(`/admin/search`, searchCriteria);
        return data;
    } else if (searchBy === 'employer') {
        const { data } = await getInstance().post<{ data: SearchResponse; error: string }, any>(`/employer/search`, searchCriteria);
        return data;
    } else if (searchBy === 'public') {
        // call public api
        const { data } = await getInstance().post<{ data: SearchResponse; error: string }, any>(`/public/search`, searchCriteria);
        return data;
    }
};
