import { profileData } from './profileReducer';
import { AdminUser } from '../../types/admin';
import { Profile } from '../../types/CandidateProfile';

interface AdminState {
    userMetrics: {
        OPEN?: number;
        SEEKING?: number;
        NOT_SEEKING?: number;
        null?: number;
    };
    usersList: AdminUser[];
    userOverview: Profile;
    loading: boolean;
}

const initialState: AdminState = {
    userMetrics: {},
    usersList: [],
    userOverview: profileData,
    loading: false,
};

const adminReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'GET_ADMIN_USER_METRICS':
            return {
                ...state,
                userMetrics: action.payload as AdminState['userMetrics'],
                loading: false,
            } as AdminState;
        case 'GET_ADMIN_USERS':
            return {
                ...state,
                usersList: action.payload,
                loading: false,
            } as AdminState;
        case 'GET_ADMIN_USER_OVERVIEW':
            return {
                ...state,
                userOverview: { ...profileData, ...action.payload },
                loading: false,
            } as AdminState;
        default:
            return state as AdminState;
    }
};

export default adminReducer;
