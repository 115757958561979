type FetchInstance = {
    get: <T extends ErrorResponse>(url: string, options?: any) => Promise<T>;
    put: <T extends ErrorResponse, B>(url: string, body?: B | undefined, options?: any) => Promise<T>;
    delete: <T extends ErrorResponse, B>(url: string, body?: B | undefined, options?: any) => Promise<T>;
    post: <T extends ErrorResponse, B>(url: string, body?: B | undefined, options?: any) => Promise<T>;
};

let fetchInstance: FetchInstance;

interface ErrorResponse {
    error?: string;
}

const execution =
    (baseUrl: string, token?: string) =>
    async <T extends ErrorResponse, B>(method: string, path: string, body: B | undefined, options: any = {}): Promise<T> => {
        try {
            const inlineOptions: RequestInit = {
                method,
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'include', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'x-xsrf-token': token || '',
                    ...options.headers,
                },
                body: typeof body === 'object' ? JSON.stringify(body) : undefined,
                redirect: 'manual',
            };
            if (options.isForm) {
                delete ((inlineOptions?.headers as any) || {})['Content-Type'];
                inlineOptions.body = body as any;
            }
            const res = await fetch(`${baseUrl}${path}`, inlineOptions);
            if (res.type === 'opaqueredirect') {
                if (window?.location) {
                    window.location.replace(res.url); // redirect here - if it doesn't work then probably error out
                    return {} as any;
                } else {
                    return { error: 'Could not redirect' } as any;
                }
            }
            // unauthorised
            if (res.status === 401) {
                return window?.location?.replace('/logout') as any;
            }

            const response = await res.json();
            if (response?.data?.redirect) {
                window?.location?.replace(response?.data?.redirect);
                return {} as any;
            }
            return response;
        } catch (err) {
            return { error: err } as T;
        }
    };

const postCall =
    (baseUrl: string, token?: string) =>
    async <T extends ErrorResponse, B>(url: string, body: B | undefined, options?: any): Promise<T> =>
        execution(baseUrl, token)<T, B>('POST', url, body, options);

const getCall =
    (baseUrl: string, token?: string) =>
    async <T extends ErrorResponse>(url: string, options?: any): Promise<T> =>
        execution(baseUrl, token)<T, null>('GET', url, undefined, options);

const putCall =
    (baseUrl: string, token?: string) =>
    async <T extends ErrorResponse, B>(url: string, body: B | undefined, options?: any): Promise<T> =>
        execution(baseUrl, token)<T, B>('PUT', url, body, options);

const deleteCall =
    (baseUrl: string, token?: string) =>
    async <T extends ErrorResponse, B>(url: string, body: B | undefined, options?: any): Promise<T> =>
        execution(baseUrl, token)<T, B>('DELETE', url, body, options);

const setup = (baseUrl: string): FetchInstance => {
    fetchInstance = {
        get: getCall(baseUrl),
        post: postCall(baseUrl),
        put: putCall(baseUrl),
        delete: deleteCall(baseUrl),
    };
    return fetchInstance;
};

const setupWithToken = (baseUrl: string, token: string): FetchInstance => {
    fetchInstance = {
        get: getCall(baseUrl, token),
        post: postCall(baseUrl, token),
        put: putCall(baseUrl, token),
        delete: deleteCall(baseUrl, token),
    };
    return fetchInstance;
};

const getInstance = (): FetchInstance => {
    if (!fetchInstance) {
        throw Error('Fetch called before initialised');
    }
    return fetchInstance;
};

export { setup, setupWithToken, getInstance };
