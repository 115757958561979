import { ProfileFiles } from '../../types/CandidateProfile';

interface FileState {
    filesData: ProfileFiles[];
    isLoading: boolean;
}

const initialState: FileState = {
    filesData: [],
    isLoading: false,
};

const filesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'GET_FILES':
            return {
                ...state,
                filesData: action.payload,
            } as FileState;
        case 'GET_FILES_ERROR':
            return {
                ...state,
                errorMsg: action.payload,
            } as FileState;
        case 'FILE_UPLOAD_ERROR':
            return {
                ...state,
                errorMsg: action.payload,
            } as FileState;
        case 'FILE_DOWNLOAD_ERROR':
            return {
                ...state,
                errorMsg: action.payload,
            } as FileState;
        case 'FILE_DELETE_ERROR':
            return {
                ...state,
                errorMsg: action.payload,
            } as FileState;
        case 'UPLOADING_FILE':
            return {
                ...state,
                isLoading: action.payload,
            } as FileState;
        default:
            return state;
    }
};

export default filesReducer;
