export interface DynamicConfig {
    apiUrl: string;
    serverUri: string;
}

export const defaultConfig: DynamicConfig = {
    apiUrl: '',
    serverUri: '',
};

export const dynamicConfigUrl = 'config.json';

export const enum Environment {
    DEVELOPMENT = 'development',
    PRODUCTION = 'production',
    TEST = 'test',
}

export const getDefaultConfig = (environment: string) => {
    switch (environment) {
        case Environment.DEVELOPMENT:
            return {
                apiUrl: 'https://api.dev.aukusjobs.com/api/v1',
                serverUri: 'https://app.dev.aukusjobs.com',
            };
        case Environment.PRODUCTION:
            return {
                apiUrl: 'https://api.aukusjobs.com/api/v1',
                serverUri: 'https://app.aukusjobs.com',
            };

        case Environment.TEST:
            return {
                apiUrl: 'http://localhost:3001/api/v1',
                serverUri: 'http://localhost:3000',
            };
        default:
            return {
                apiUrl: 'https://api.dev.aukusjobs.com/api/v1',
                serverUri: 'https://app.dev.aukusjobs.com',
            };
    }
};
