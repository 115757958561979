import { useState, useEffect } from 'react';

type StorageValue<T> = [T, (value: T) => void];

export function getStorageValue<T>(key: string, defaultValue: T): T {
    const saved = localStorage.getItem(key);
    if (saved === null || saved === 'undefined') {
        return defaultValue;
    }
    const initial = JSON.parse(saved) as T;
    return initial;
}

export function useLocalStorage<T>(key: string, defaultValue: T): StorageValue<T> {
    const [value, setValue] = useState<T>(() => {
        return getStorageValue<T>(key, defaultValue);
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
}
