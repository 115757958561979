import { getInstance } from '../../services/fetch';
import { Shortlist } from '../../types/shortlist';
import { OrganisationUsers } from '../../types/organisation';
import { AppDispatch } from '../store';
import { showSuccessSnackbar, showErrorSnackbar } from './snackbarAction';

export const getShortlists = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: Shortlist[]; error: string }>(`/employer/shortlist`);

        dispatch({
            type: 'GET_SHORTLISTS',
            payload: response.data,
        });
    } catch (e) {
        dispatch(showErrorSnackbar(`An error occurred while getting the shortlists`));
    }
};
export const createNewShortlists = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().post<{ data: Shortlist[]; error: string }, any>(`/employer/shortlist/new`, {
            id: Date.now().toString(36) + Math.random().toString(36).substring(13),
        });
        const newShortList = response.data;

        dispatch({
            type: 'SET_SHORTLISTS',
            payload: newShortList,
        });
        return newShortList;
    } catch (e) {
        dispatch(showErrorSnackbar(`An error occurred while getting the shortlists`));
    }
};

export const getSharedShortlists = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: Shortlist[]; error: string }>(`/employer/shortlist/shared`);

        dispatch({
            type: 'GET_SHARED_SHORTLISTS',
            payload: response.data,
        });
    } catch (e) {
        dispatch(showErrorSnackbar(`An error occurred while getting the shared shortlists`));
    }
};

export const describeShortlist = (uuid: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: Shortlist[]; error: string }>(`/employer/shortlist/${uuid}`);

        dispatch({
            type: 'DESCRIBE_SHORTLIST',
            payload: response.data,
        });
    } catch (e) {
        dispatch(showErrorSnackbar(`An error occurred while describing the shortlist`));
    }
};

export const describeSharedShortlist = (uuid: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: Shortlist[]; error: string }>(`/employer/shortlist/${uuid}`);
        dispatch({
            type: 'DESCRIBE_SHARED_SHORTLIST',
            payload: response.data,
        });
    } catch (e) {
        dispatch(showErrorSnackbar(`An error occurred while describing the shared shortlist`));
    }
};

export const updateShortlist = (shortlist: Shortlist) => (dispatch: AppDispatch) => {
    try {
        dispatch({
            type: 'UPDATE_SHORTLIST',
            payload: shortlist,
        });
    } catch (e) {
        dispatch(showErrorSnackbar(`An error occurred while updating the shortlist`));
    }
};

export const upsertShortlist = (shortlist: Shortlist, message?: string) => async (dispatch: AppDispatch) => {
    try {
        await getInstance().post<{ error: string }, any>(`/employer/shortlist`, shortlist);
        await getShortlists()(dispatch);
        if (shortlist.uuid) {
            describeShortlist(shortlist.uuid)(dispatch);
            if (message) {
                dispatch(showSuccessSnackbar(message));
            }
            return;
        }
        dispatch(showSuccessSnackbar('Candidate(s) added to shortlist successfully!'));
    } catch (e) {
        dispatch(showErrorSnackbar(`An error occurred while upserting the shortlist`));
    }
};

export const deleteShortlist = (uuid: string) => async (dispatch: AppDispatch) => {
    try {
        await getInstance().delete<{ error: string }, any>(`/employer/shortlist/${uuid}`);
        await getShortlists()(dispatch);
        dispatch(showSuccessSnackbar('Shortlist deleted successfully!'));
    } catch (e) {
        dispatch(showErrorSnackbar(`An error occurred while deleting the shortlist`));
    }
};

/**  Note to future dev: A feature in which you can share a shortlist to a specific person within the organisation has been discussed. You can create another action based on the logic below to achieve that feature. Happy hacking!!! */

export const shareShortlist = (currentShortlist: Shortlist) => async (dispatch: AppDispatch) => {
    try {
        /** get users within the organisation */
        const response = await getInstance().get<{ data: OrganisationUsers[]; error: string }>(`/admin/org/users`);
        /** ID's of the users within the organisation except the owner's ID */
        const sharedTo = response.data.map((users) => users.Attributes[0].Value).filter((users) => users !== currentShortlist.owner);
        const message = 'Shortlist shared to everyone within your organisation successfully!';

        currentShortlist.sharedTo = sharedTo;
        await upsertShortlist(currentShortlist, message)(dispatch);
    } catch (e) {
        dispatch(showErrorSnackbar(`An error occurred while sharing the shortlist`));
    }
};
