interface UserState {
    token: object;
}

const initialState: UserState = {
    token: {},
};

const userReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'GET_USER':
            return {
                ...state,
                token: action.payload,
            };
        default:
            return state;
    }
};

export default userReducer;
