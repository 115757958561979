import { getInstance } from '../../services/fetch';
import { SkillsAnswer, SkillsAnswers } from '../../types/answer';
import { Skill } from '../../types/skill';
import { AppDispatch } from '../store';
import { showErrorSnackbar } from './snackbarAction';

export const getSkillsFramework = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: Skill[]; error: string }>(`/skills`);
        dispatch({
            type: 'GET_SKILLS',
            payload: response.data,
        });
    } catch (e: any) {
        dispatch({
            type: 'GET_SKILLS_ERROR',
            payload: e?.message,
        });
        dispatch(showErrorSnackbar(`An error occurred while getting the skills framework`));
    }
};

export const getSkillsAnswers = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: SkillsAnswers; error: string }>(`/seeker/skills/answers`);
        dispatch({
            type: 'GET_SKILLS_ANSWERS',
            payload: response.data,
        });
    } catch (e: any) {
        dispatch({
            type: 'GET_SKILLS_ANSWERS_ERROR',
            payload: e?.message,
        });
        dispatch(showErrorSnackbar(`An error occurred while getting the skills answers`));
    }
};

export const upsertSkillsAnswers = (answers: SkillsAnswer) => async (dispatch: AppDispatch) => {
    try {
        await getInstance().post<{ error: string }, any>(`/seeker/skills/answers`, answers);
        await getSkillsAnswers()(dispatch);
    } catch (e: any) {
        dispatch({
            type: 'SKILLS_ANSWERS_UPSERT_ERROR',
            payload: e?.message,
        });
        console.error('SkillsAnswer upsert error');
        dispatch(showErrorSnackbar(`An error occurred while upserting the skills answers`));
    }
};
