import React, { HTMLAttributes } from 'react';
import { styled } from '@mui/material';

const ToggleContainer = styled('div')(({ theme }) => {
    return `
        box-sizing: border-box;
        font-weight: 500;
        border: solid 2px ${theme.palette.primary.main};
        background: ${theme.palette.primary.main};
        border-radius: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    `;
});

const ToggleOptionLabel = styled('div')(() => {
    return `

    `;
});

const ToggleOption = styled('div')(({ theme }) => {
    return `
        overflow: hidden;
        flex: 1;
        height: 100%;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(:first-child) {
            border-left: solid 2px ${theme.palette.primary.main};
        }

        &:first-child {
            padding-left: 0.5rem;
        }

        &:last-child {
            padding-right: 0.5rem;
        }

        &:not(.active) {
            cursor: pointer;
        }

        color: ${theme.palette.primary.main};
        background: ${theme.palette.common.white};

        &.active {
            background: ${theme.palette.primary.main};
            color: ${theme.palette.common.white};
        }
    `;
});

export type ToggleOptions = Array<{
    value: string;
    label: string;
}>;

export type ToggleProps = HTMLAttributes<HTMLDivElement> & {
    value: string;
    options: ToggleOptions;
    onValueChange: (newValue: string) => void;
};

export const Toggle: React.FC<ToggleProps> = ({ value, options, onValueChange }) => {
    return (
        <ToggleContainer>
            {options.map((option) => {
                const isActive = option.value === value;
                return (
                    <ToggleOption
                        key={option.value}
                        className={[option.value, isActive ? 'active' : '']
                            .filter((v) => {
                                return !!v;
                            })
                            .join(' ')}
                        onClick={() => {
                            if (isActive) {
                                return;
                            }

                            onValueChange(option.value as string);
                        }}
                    >
                        <ToggleOptionLabel>{option.label}</ToggleOptionLabel>
                    </ToggleOption>
                );
            })}
        </ToggleContainer>
    );
};

export default Toggle;
