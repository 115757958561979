import { ProfileResult, SearchRequest } from '../../types/search';

interface SearchState {
    // selectedCriteria: [string, string, string];
    // selectedSkill: [string, string, string, string];
    // selectedSkillExp: string;
    searchCriteria: SearchRequest;
    searchResults: ProfileResult[];
}

const initialState: SearchState = {
    // selectedCriteria: ['', '', ''],
    // selectedSkill: ['', '', '', ''],
    // selectedSkillExp: '',
    searchCriteria: {} as SearchRequest,
    searchResults: [],
};

const searchReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_SEARCH_CRITERIA':
            return {
                ...state,
                searchCriteria: action.payload,
            } as SearchState;
        case 'SET_SELECTED_CRITERIA':
            return {
                ...state,
                selectedCriteria: action.payload,
            } as SearchState;
        case 'SET_SELECTED_SKILL':
            return {
                ...state,
                selectedSkill: action.payload,
            } as SearchState;
        case 'SET_SELECTED_SKILL_EXP':
            return {
                ...state,
                selectedSkillExp: action.payload,
            } as SearchState;
        case 'SET_SEARCH_RESULTS':
            return {
                ...state,
                searchResults: action.payload.results,
            } as SearchState;
        default:
            return state;
    }
};

export default searchReducer;
