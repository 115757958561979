export const compareTwoArray = (array1: any[], array2: any[]): boolean => {
    /**
     * check length
     * sort the array
     * check each item
     */
    let result = false;
    if (array1.length !== array2.length) return result;
    const sortedArray1 = array1.sort();
    const sortedArray2 = array2.sort();
    result = sortedArray1.every((item, idx) => {
        return item === sortedArray2[idx];
    });
    return result;
};

export const checkSubArray = (parent: any[], sub: any[]): boolean => {
    if (sub.length > parent.length) return false;
    return sub.every((item) => {
        return parent.includes(item);
    });
};

// const cars = [
//     { brand: 'Audi', produced: '2016', color: 'black' },
//     { brand: 'Audi', produced: '2017', color: 'white' },
//     { brand: 'Ford', produced: '2016', color: 'red' },
//     { brand: 'Ford', produced: '2016', color: 'white' },
//     { brand: 'Peugot', produced: '2018', color: 'white' }
//   ];

//   const groupByBrand = groupBy(['brand']);
//   const groupByColor = groupBy(['color']);
//   const groupByBrandAndYear = groupBy(['brand', 'produced']);

//   console.log(
//     JSON.stringify({
//       carsByBrand: groupByBrand(cars),
//       carsByColor: groupByColor(cars),
//       carsByBrandAndYear: groupByBrandAndYear(cars)
//     }, null, 2)
//   );

/**
 * Group array of objects by given keys
 * @param keys keys to be grouped by
 * @param array objects to be grouped
 * @returns an object with objects in `array` grouped by `keys`
 */

export const groupBy =
    <T>(keys: (keyof T)[]) =>
    (array: T[]): Record<string, T[]> =>
        array.reduce((objectsByKeyValue, obj) => {
            const value = keys.map((key) => obj[key]).join('-');
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
            return objectsByKeyValue;
        }, {} as Record<string, T[]>);
