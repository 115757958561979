import { clone, mergeRight } from 'ramda';
import { Shortlist } from '../../types/shortlist';

interface ShortlistState {
    shortlistData: Shortlist[];
    sharedShortlistData: Shortlist[];
    shortlist: Shortlist;
    sharedShortlist: Shortlist;
}

const defaultShortlistState = {
    name: '',
    description: '',
    items: [],
};

const initialState: ShortlistState = {
    shortlistData: [],
    sharedShortlistData: [],
    shortlist: clone(defaultShortlistState),
    sharedShortlist: clone(defaultShortlistState),
};

const shortlistReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'GET_SHORTLISTS':
            return {
                ...state,
                shortlistData: action.payload,
            } as ShortlistState;
        case 'SET_SHORTLISTS':
            return {
                ...state,
                shortlist: action.payload,
            } as ShortlistState;
        case 'GET_SHARED_SHORTLISTS':
            return {
                ...state,
                sharedShortlistData: action.payload,
            } as ShortlistState;
        case 'DESCRIBE_SHORTLIST':
            return {
                ...state,
                shortlist: mergeRight(clone(defaultShortlistState), action.payload),
            } as ShortlistState;
        case 'DESCRIBE_SHARED_SHORTLIST':
            return {
                ...state,
                sharedShortlist: mergeRight(clone(defaultShortlistState), action.payload),
            } as ShortlistState;
        case 'UPDATE_SHORTLIST':
            return {
                ...state,
                shortlist: action.payload,
            } as ShortlistState;
        default:
            return state;
    }
};

export default shortlistReducer;
