import { Profile as CandidateProfile, ProfileOverview } from '../../types/CandidateProfile';
import { mergeDeepRight } from 'ramda';

interface ProfileState {
    profileData: CandidateProfile;
    profileOverview: ProfileOverview;
    loading: boolean;
    activeStep: number;
    congratsMessage: boolean;
}

export const profileData: CandidateProfile = {
    candidateId: '',
    phoneNumber: '',
    email: '',
    firstname: '',
    surname: '',
    accountType: 'EMPTY',
    securityClearance: [],
    nationality: [],
    auCitizenshipStatus: '',
    location: {
        city: '',
        state: '',
        country: '',
        postcode: '',
    },
    linkedInUrl: '',
    readiness: 'EMPTY',
    availabilityComment: '',
    mobilityComment: '',
    seeking: [],
    gender: '',
    veteranStatus: '',
    partnerOrFamily: '',
    returningToWorkforce: '',
    aboriginalOrTorresStrait: '',
    preExistingConditions: '',
    careerSummary: '',
    lastUpdated: '',
    organisationName: '',
    openProfile: false,
    wizardCompleted: true,
    requestedEmployerAccess: false,
};

const initialState: ProfileState = {
    profileData,
    profileOverview: {
        profile: { ...profileData },
        skills: {},
        files: {
            files: [],
        },
        userId: '',
    },
    loading: true,
    activeStep: 0,
    congratsMessage: false,
};

const profileReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'GET_PROFILE':
            const mergedProfile = mergeDeepRight(state.profileData, action.payload);
            return { ...state, profileData: mergedProfile, loading: false } as ProfileState;
        case 'GET_PROFILE_OVERVIEW':
            return { ...state, profileOverview: action.payload, loading: false } as ProfileState;
        case 'SET_PROFILE_OVERVIEW_USER_ID':
            const overview = {
                ...state.profileOverview,
                userId: action.payload,
            };
            return {
                ...state,
                profileOverview: overview,
                loading: false,
            } as ProfileState;
        case 'SET_ACTIVE_STEP':
            return { ...state, activeStep: action.payload } as ProfileState;
        case 'CONGRATS_MESSAGE':
            return { ...state, congratsMessage: action.payload } as ProfileState;
        default:
            return state as ProfileState;
    }
};

export default profileReducer;
