import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import { Alert, AlertColor } from '@mui/material';
import { clearSnackbar } from '../redux/actions/snackbarAction';
import { selectSnackbarMessage, selectSnackbarOpen, selectSnackbarSeverity } from '../redux/selectors/snackbarSelector';

export default function NovoSnackbar() {
    const dispatch = useDispatch();

    const snackbarSeverity = useSelector(selectSnackbarSeverity);
    const snackbarMessage = useSelector(selectSnackbarMessage);
    const snackbarOpen = useSelector(selectSnackbarOpen);

    function handleClose(_event: React.SyntheticEvent | Event, reason?: string) {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(clearSnackbar());
    }

    let severity: AlertColor = 'info';
    let borderColor = '#0288d1';
    let duration = 4000;

    switch (snackbarSeverity) {
        case 'error':
            severity = 'error';
            duration = 6000;
            borderColor = '#d32f2f';
            break;
        case 'warning':
            severity = 'warning';
            borderColor = '##ed6c02';
            break;
        case 'success':
            severity = 'success';
            borderColor = '#2e7d32';
            break;
        default:
            severity = 'info';
            duration = 10000;
            break;
    }

    return (
        <Stack spacing={4} sx={{ width: '700px' }}>
            <Snackbar
                sx={{
                    border: `1.5px solid ${borderColor}`,
                    borderRadius: '0.375rem',
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={duration}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={severity} sx={{ width: '700px' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Stack>
    );
}
