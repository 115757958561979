import { CSSProperties } from 'react';
import { AccordionSummary, Typography, AccordionDetails, styled } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Variant } from '@mui/material/styles/createTypography';

type componentProps = {
    title: string;
    children: JSX.Element;
    titleStyle?: CSSProperties;
    titleVariant?: Variant;
    titleColor?: string;
    open: boolean;
    onChange: (value: boolean) => void;
};
const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(() => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const SkillsAccordion = ({ title, children, titleStyle, titleVariant = 'body1', titleColor, open, onChange }: componentProps) => {
    return (
        <Accordion
            expanded={open}
            onChange={() => {
                onChange(!open);
            }}
        >
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon color="primary" />} sx={{ padding: 0 }}>
                <Typography variant={titleVariant} color={titleColor} style={titleStyle}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0' }}>{children}</AccordionDetails>
        </Accordion>
    );
};

export default SkillsAccordion;
