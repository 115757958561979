interface ProfileCommentState {
    comment: any;
    loading: boolean;
}

const initialState: ProfileCommentState = {
    comment: '',
    loading: false,
};

const profileCommentsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'GET_PROFILE_COMMENT':
            return {
                ...state,
                comment: action.payload,
                loading: false,
            } as ProfileCommentState;
        default:
            return state as ProfileCommentState;
    }
};

export default profileCommentsReducer;
