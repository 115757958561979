import { Button, ButtonProps, styled } from '@mui/material';

export const PublicOutlineButton = styled(Button)<ButtonProps>(() => ({
    color: '#F38F2F',
    border: '1px solid #F38F2F',
    '&:hover': {
        backgroundColor: '#F38F2F',
        color: 'white',
    },
}));

export const PublicContainButton = styled(Button)<ButtonProps>(() => ({
    backgroundColor: '#F38F2F',
    color: 'white',
    '&:hover': {
        backgroundColor: '#F38F2F',
        color: 'white',
    },
}));
