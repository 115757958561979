import { Geography, GeographyPrefs } from '../../types/geography';

interface GeographyState {
    geographyData: Geography[];
    geographyPreferances: GeographyPrefs;
    loading: boolean;
}

const initialState: GeographyState = {
    geographyData: [],
    geographyPreferances: {},
    loading: false,
};

const geographyReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'GET_GEOGRAPHY':
            return {
                ...state,
                geographyData: action.payload,
                loading: false,
            } as GeographyState;
        case 'GET_GEOGRAPHY_PREFS':
            return {
                ...state,
                geographyPreferances: action.payload,
                loading: false,
            } as GeographyState;
        case 'GEOGRAPHY_GET_ERROR':
            return {
                ...state,
                errorMsg: action.payload,
                loading: true,
            } as GeographyState;

        default:
            return state;
    }
};

export default geographyReducer;
