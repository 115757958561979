import { ProfileResult, SearchRequest } from '../../types/search';

interface PublicSearchState {
    // selectedCriteria: [string, string, string];
    // selectedSkill: [string, string, string, string];
    // selectedSkillExp: string;
    searchCriteria: SearchRequest;
    searchResults: ProfileResult[];
}

const initialState: PublicSearchState = {
    // selectedCriteria: ['', '', ''],
    // selectedSkill: ['', '', '', ''],
    // selectedSkillExp: '',
    searchCriteria: {} as SearchRequest,
    searchResults: [],
};

const publicSearchReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_PUBLIC_SEARCH_CRITERIA':
            return {
                ...state,
                searchCriteria: action.payload,
            } as PublicSearchState;
        case 'SET_PUBLIC_SELECTED_CRITERIA':
            return {
                ...state,
                selectedCriteria: action.payload,
            } as PublicSearchState;
        case 'SET_PUBLIC_SELECTED_SKILL':
            return {
                ...state,
                selectedSkill: action.payload,
            } as PublicSearchState;
        case 'SET_PUBLIC_SELECTED_SKILL_EXP':
            return {
                ...state,
                selectedSkillExp: action.payload,
            } as PublicSearchState;
        case 'SET_PUBLIC_SEARCH_RESULTS':
            return {
                ...state,
                searchResults: action.payload.results,
            } as PublicSearchState;
        default:
            return state;
    }
};

export default publicSearchReducer;
