import { getInstance } from '../../services/fetch';
import { Profile } from '../../types/CandidateProfile';
import { AdminSkill } from '../../types/skill';
import { getErrorMessage } from '../../utils/getErrorMessage';
import { AppDispatch } from '../store';
import { showErrorSnackbar } from './snackbarAction';

export const getAdminSkillsFramework = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: AdminSkill[]; error: string }>(`/admin/skills`);

        dispatch({
            type: 'GET_ADMIN_SKILLS',
            payload: response.data,
        });
    } catch (e: any) {
        dispatch({
            type: 'GET_ADMIN_SKILLS_ERROR',
            payload: e?.message,
        });
        dispatch(showErrorSnackbar(`An error occurred while getting the skills admin framework`));
    }
};

export const getAdminUserMetrics = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: AdminSkill[]; error: string }>(`/admin/users/metrics`);

        dispatch({
            type: 'GET_ADMIN_USER_METRICS',
            payload: response.data,
        });
    } catch (e: any) {
        dispatch({
            type: 'GET_ADMIN_USER_METRICS_ERROR',
            payload: e?.message,
        });
        dispatch(showErrorSnackbar(`An error occurred while getting the user metrics`));
    }
};

export const getAdminUserExport = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: { data: string }; error: string }>(`/admin/users/export`);

        if (response.data) {
            const dataStr = window.URL.createObjectURL(new Blob([response.data.data]));
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', dataStr);
            downloadAnchorNode.setAttribute('download', `aukus-users-export.csv`);
            document.body.appendChild(downloadAnchorNode);
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        }
    } catch (e: any) {
        dispatch(showErrorSnackbar(`An error occurred while exporting the users`));
    }
};

export const upsertSkill = (skill: AdminSkill) => async (dispatch: AppDispatch) => {
    try {
        await getInstance().post<{ error: string }, any>(`/admin/skills`, [skill]);
        await getAdminSkillsFramework()(dispatch);
    } catch (e: any) {
        dispatch({
            type: 'SKILLS_UPSERT_ERROR',
            payload: e?.message,
        });
        console.error('Skills upsert error');
        dispatch(showErrorSnackbar(`An error occurred while upserting a skill`));
    }
};

export const deleteAdminSkill = (skillUuid: string) => async (dispatch: AppDispatch) => {
    try {
        await getInstance().delete<{ error: string }, any>(`/admin/skills`, [skillUuid]);
        await getAdminSkillsFramework()(dispatch);
    } catch (e: any) {
        dispatch({
            type: 'SKILL_DELETE_ERROR',
            payload: e?.message,
        });
        console.error('Skills delete error');
        dispatch(showErrorSnackbar(`An error occurred while deleting a skill`));
    }
};

export const getAdminUsers = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: AdminSkill[]; error: string }>(`/admin/users`);

        dispatch({
            type: 'GET_ADMIN_USERS',
            payload: response.data,
        });
    } catch (e: any) {
        dispatch(showErrorSnackbar(`An error occurred while getting the list of users. Please try again later.`));
    }
};

export const getAdminUserOverview = (userId: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: Profile; error: string }>(`/admin/users/overview?userId=${userId}`);

        dispatch({
            type: 'GET_ADMIN_USER_OVERVIEW',
            payload: response.data,
        });
    } catch (e: any) {
        dispatch(showErrorSnackbar(`An error occurred while getting user overview. Please try again later.`));
    }
};

export const adminUserDelete = (userId: string) => async (dispatch: AppDispatch) => {
    try {
        await getInstance().delete<{ error: string }, any>(`/admin/users/profile?userId=${userId}`);
        await getAdminUsers()(dispatch);
    } catch (e: any) {
        dispatch(showErrorSnackbar(`An error occurred while deleting a user. Please try again later.`));
    }
};

export const adminDownloadFile = (userId: string, uuid: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: any; error: string }>(`/admin/users/files/${userId}/${uuid}`);
        //presigned url back from api
        window.open(response.data.url, '_blank');
    } catch (e) {
        dispatch({
            type: 'FILE_DOWNLOAD_ERROR',
            payload: getErrorMessage(e),
        });
        console.error('Download file error');
        dispatch(showErrorSnackbar(`Download file error`));
    }
};
