import { Job } from '../../types/job';

interface JobState {
    jobs: Job[];
    selectedJob: Job;
    loading: boolean;
}

const initialState: JobState = {
    jobs: [],
    selectedJob: { uuid: '' },
    loading: false,
};

const JobReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_JOBS':
            return {
                ...state,
                jobs: action.payload,
                loading: false,
            } as JobState;
        case 'SET_SELECTED_JOB':
            return {
                ...state,
                selectedJob: action.payload,
                loading: false,
            } as JobState;
        case 'JOB_SET_LOADING':
            return {
                ...state,
                loading: action.payload,
            } as JobState;
        default:
            return state as JobState;
    }
};

export default JobReducer;
