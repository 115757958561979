import { Grid, CardContent, Typography, CardActions, Divider, TablePagination } from '@mui/material';
import { PublicOutlineButton } from './public/button';
import { SearchRequest } from '../types/search';
import { Skill } from '../types/skill';
import { CardPaginationDefault, SkillModifierMap, skillsAnswersMap } from '../constant';
import { useContext, useState } from 'react';
import { paginate } from '../utils/paginate';
import { AukusCard } from './card';
import { configContextObject } from '../config/ConfigProvider';

type Props = {
    readonly rows: readonly any[];
    searchRequest: SearchRequest;
    skills: Skill[];
};

export const renderAddress = (row: any) => {
    if (row.location) {
        const { city, state, country } = row.location;
        return [city, state, country].filter(Boolean).join(',');
    }
    return '';
};

const DefaultPage = 0;

export const ViewAsColumnCandidate = (props: Props) => {
    const { config } = useContext(configContextObject);
    const { rows, searchRequest, skills } = props;
    const skillsInSearch = searchRequest.skills && Object.keys(searchRequest.skills).length > 0;
    const geoInSearch = searchRequest.geography && Object.keys(searchRequest.geography).length > 0;
    const emptySearch = !skillsInSearch && !geoInSearch;
    const [page, setPage] = useState(DefaultPage);
    const [rowsPerPage, setRowsPerPage] = useState(CardPaginationDefault);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(DefaultPage);
    };

    const handleViewProfile = (row: any) => {
        // get value from contex

        window.open(`${config.serverUri}/candidate/${row.userId}`, '_blank');
    };

    return (
        <>
            <Grid container mb={'1rem'}>
                {/* filter this row based on pagaination  */}
                {paginate(rows, rowsPerPage, page).map((row, index) => (
                    <Grid item key={index} xs={12} md={4} xl={4} p={'1rem'}>
                        <AukusCard>
                            <CardContent>
                                <Grid
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    style={{ background: '#1B717A', padding: '0.5rem', borderRadius: '4px', color: 'white' }}
                                >
                                    <b style={{ marginRight: '0.2rem' }}>Candidate</b> <b>{row.candidateId}</b>
                                </Grid>
                                <Grid mb={'0.5rem'} mt={'1rem'}>
                                    <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'0.25rem'}>
                                        <Typography variant="body2" color={'primary'}>
                                            {emptySearch ? 'Last Updated: ' : 'Rank: '}
                                        </Typography>
                                        <Typography sx={{ fontWeight: '500' }} color="#F38F2F">
                                            {` ${row?.rank}`}
                                        </Typography>
                                    </Grid>

                                    <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'0.25rem'}>
                                        <Typography variant="body2" color={'primary'}>
                                            {'Location: '}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontWeight: '500' }} color="#F38F2F">
                                            {renderAddress(row)}
                                        </Typography>
                                    </Grid>
                                    <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'0.25rem'}>
                                        <Typography variant="body2" color={'primary'}>
                                            {'Aust. Citizenship:'}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontWeight: '500' }} color="#F38F2F">
                                            {row?.auCitizenshipStatus}
                                        </Typography>
                                    </Grid>
                                    <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'1rem'}>
                                        <Typography variant="body2" color={'primary'}>
                                            {'Aust. Security Clearance: '}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontWeight: '500' }} color="#F38F2F">
                                            {row.securityClearance ? 'Yes' : 'No'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {skillsInSearch && (
                                    <>
                                        <Divider sx={{ borderWidth: '1px' }} />
                                        <Grid mt={'1rem'}>
                                            {Object.entries(searchRequest?.skills ?? {})
                                                .map(([key, value]) => {
                                                    const skill = skills.find((skill) => skill.id === key);
                                                    const title = `${skill?.display}:`;
                                                    const result = skillsAnswersMap.get(row[key]) || `-----`;
                                                    const sortOrder = SkillModifierMap.get(value.modifier || '') || 0;
                                                    const response = { title, result, key, sortOrder };
                                                    return response;
                                                })
                                                .sort((a, b) => b.sortOrder - a.sortOrder)
                                                .map((item) => {
                                                    const { title, result, key } = item;
                                                    return (
                                                        <Grid display={'flex'} alignItems={'center'} key={key} justifyContent={'space-between'} mb={'0.25rem'}>
                                                            <Typography variant="body2" color={'primary'}>
                                                                {title}
                                                            </Typography>
                                                            <Typography variant="body2" sx={{ fontWeight: '500' }} color="#F38F2F">
                                                                {result}
                                                            </Typography>
                                                        </Grid>
                                                    );
                                                })}
                                        </Grid>
                                    </>
                                )}
                            </CardContent>
                            <CardActions sx={{ padding: '1rem' }}>
                                <PublicOutlineButton fullWidth onClick={() => handleViewProfile(row)}>
                                    View Profile
                                </PublicOutlineButton>
                            </CardActions>
                        </AukusCard>
                    </Grid>
                ))}
            </Grid>
            <TablePagination
                component="div"
                count={rows.length}
                page={page}
                rowsPerPageOptions={[9, 25, 50, 100]}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};
