import { SkillsAnswers } from '../../types/answer';
import { AdminSkill, Skill } from '../../types/skill';

interface SkillsState {
    skillsData: Skill[];
    skillsAnswers: SkillsAnswers;
    adminSkillsData: AdminSkill[];
    loading: boolean;
}

const initialState: SkillsState = {
    skillsData: [],
    skillsAnswers: {},
    adminSkillsData: [],
    loading: false,
};

const skillsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'GET_SKILLS':
            return {
                ...state,
                skillsData: action.payload,
                loading: false,
            } as SkillsState;
        case 'GET_ADMIN_SKILLS':
            return {
                ...state,
                adminSkillsData: action.payload,
                loading: false,
            } as SkillsState;
        case 'GET_SKILLS_ANSWERS':
            return {
                ...state,
                skillsAnswers: action.payload,
                loading: false,
            } as SkillsState;
        case 'SKILLS_SET_LOADING':
            return {
                ...state,
                loading: action.payload,
            } as SkillsState;
        default:
            return state as SkillsState;
    }
};

export default skillsReducer;
