import React, { useContext, useEffect, useState } from 'react';
import { defaultConfig, DynamicConfig, Environment, getDefaultConfig } from './config';
import { setup } from '../services/fetch';

interface DynamicConfigContext {
    config: DynamicConfig;
    error: boolean;
    setConfig: (newConfig: DynamicConfig) => void;
}

export const configContextObject = React.createContext<DynamicConfigContext>({
    config: defaultConfig,
    error: false,
    setConfig: () => ({}),
});

export const useConfig = () => useContext(configContextObject);

const ConfigContextProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const [configState, setConfigState] = useState(defaultConfig);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        const _getConfig = async () => {
            const host = window.location.origin;
            let environment = '';
            if (host === 'http://localhost:3000') {
                environment = Environment.DEVELOPMENT;
            } else if (host === 'https://aukus.wp.webtectest.com.au') {
                environment = Environment.DEVELOPMENT;
            } else if (host === 'https://www.aukusjobs.com') {
                environment = Environment.PRODUCTION;
            } else {
                environment = Environment.TEST;
            }
            const response = getDefaultConfig(environment);
            setConfigState(response);
            setup(response.apiUrl);
        };
        _getConfig();
    }, []);

    return (
        <configContextObject.Provider
            value={{
                config: configState,
                error,
                setConfig: setConfigState,
            }}
        >
            {children}
        </configContextObject.Provider>
    );
};

export default ConfigContextProvider;
