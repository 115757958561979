import { CardActions, CardContent, Grid, Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { Job } from '../types/job';
import { PublicOutlineButton } from './public/button';
import { useContext, useState } from 'react';
import { paginate } from '../utils/paginate';
import { useSelector } from 'react-redux';
import { selectOrganisationsData } from '../redux/selectors/organisationsSelector';
import { AukusCard } from './card';
import { configContextObject } from '../config/ConfigProvider';
import { CardPaginationDefault } from '../constant';

const DefaultPage = 0;
type Props = {
    readonly rows: readonly Job[];
};

function renderAddress(row: Job) {
    const { city, state, country } = row;
    return [city, state, country].filter(Boolean).join(',');
}
export const ViewAsColumnJob = (props: Props) => {
    const { config } = useContext(configContextObject);
    const { rows } = props;
    const [page, setPage] = useState(DefaultPage);
    const [rowsPerPage, setRowsPerPage] = useState(CardPaginationDefault);
    const organizations = useSelector(selectOrganisationsData);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(DefaultPage);
    };

    function handleViewJob(row: Job) {
        window.open(`${config.serverUri}/jobs/${row.uuid}`, '_blank');
    }
    return (
        <>
            <Grid container mb={'1rem'}>
                {paginate(rows, rowsPerPage, page).map((row, index) => (
                    <Grid item key={index} xs={12} md={4} xl={4} p={'1rem'}>
                        <AukusCard>
                            <CardContent>
                                <Typography variant="h5" color="primary" gutterBottom>
                                    {row?.title}
                                </Typography>
                                <Grid display="flex" justifyContent="space-between" alignItems="center" mb={'1rem'}>
                                    {(organizations || [])?.find((item) => item.id === row.organization)?.meta?.logo && (
                                        <img src={(organizations || [])?.find((item) => item.id === row.organization)?.meta?.logo} style={{ width: '100px' }} />
                                    )}
                                    <div style={{ width: '70%' }}>
                                        <Typography variant="h6" color="text.primary">
                                            {(organizations || [])?.find((item) => item.id === row.organization)?.name}
                                        </Typography>
                                        {/* <Typography variant={'body2'}>Canberra, ACT, Australia</Typography> */}
                                    </div>
                                </Grid>

                                <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant="body2" color={'primary'}>
                                        {'ID: '}
                                    </Typography>
                                    <Typography variant="body2" color="#F38F2F">
                                        {row?.id}
                                    </Typography>
                                </Grid>
                                <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant="body2" color={'primary'}>
                                        {'Location(s): '}
                                    </Typography>
                                    <Typography variant="body2" color="#F38F2F">
                                        {renderAddress(row)}
                                    </Typography>
                                </Grid>
                                <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant="body2" color={'primary'}>
                                        {'Aust. Security Clearance: '}
                                    </Typography>
                                    <Typography variant="body2" color="#F38F2F">
                                        {row.securityClearance}
                                    </Typography>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <PublicOutlineButton fullWidth onClick={() => handleViewJob(row)}>
                                    View Job
                                </PublicOutlineButton>
                            </CardActions>
                        </AukusCard>
                    </Grid>
                ))}
            </Grid>
            <TablePagination
                component="div"
                count={rows.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[9, 25, 50, 100]}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};
