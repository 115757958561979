export const jobDataKEY = 'JOB_DATA';
export const jobShowSkill = 'SHOW_SKILLS';

export const ListPaginationDefault = 15;
export const CardPaginationDefault = 9;

export const skillsAnswersMap = new Map<number, string>([
    [5, 'Very high'],
    [4, 'High'],
    [3, 'Medium'],
    [2, 'Low'],
    [1, 'Very low'],
]);

export const SkillModifierMap = new Map<string, number>([
    ['MANDATORY', 4],
    ['HIGHLY_DESIRABLE', 3],
    ['DESIRABLE', 2],
    ['NICE_TO_HAVE', 1],
]);
