import React, { useState, useEffect, useContext, ReactNode } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { getProfile } from '../services';

export enum AuthStatus {
    Loading,
    SignedIn,
    SignedOut,
}

export interface IAuth {
    authStatus?: AuthStatus;
    setToken?: any;
    signOut?: any;
    user: any;
}

const defaultState: IAuth = {
    authStatus: AuthStatus.Loading,
    user: null,
};

export const AuthContext = React.createContext(defaultState);

let tempLocation: any; // use this to store tempLocation based on refresh

const LANDING_REDIRECT = 'LR';

export const PrivateRoute: React.FunctionComponent<{ children: JSX.Element }> = ({ children }) => {
    const { authStatus }: IAuth = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (authStatus !== AuthStatus.SignedIn) {
            tempLocation = location;
            navigate('/'); // this will process the redirect logic
        }
    }, [authStatus, navigate, location]);

    return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const PublicRoute: React.FunctionComponent<{ children: JSX.Element }> = ({ children }) => {
    const { authStatus }: IAuth = useContext(AuthContext);
    return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

const AuthProvider: React.FunctionComponent<{ children: JSX.Element | ReactNode }> = ({ children }) => {
    const [authStatus, setAuthStatus] = useState(AuthStatus.SignedOut);
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
    const [user, setUser] = useState<any>(null);
    // const location = useLocation();

    const navigate = useNavigate();

    function signOut() {
        setAuthStatus(AuthStatus.SignedOut);
    }

    function setToken(_accessToken: string) {
        setAccessToken(_accessToken);
    }

    useEffect(() => {
        if (authStatus === AuthStatus.SignedIn) {
            const _getProfile = async () => {
                const _user = await getProfile();
                setUser(_user);
            };
            _getProfile();
        }
    }, [authStatus, setUser]);

    useEffect(() => {
        if (accessToken != null) {
            setAccessToken(accessToken);
            setAuthStatus(AuthStatus.SignedIn);

            if (tempLocation) {
                navigate({
                    pathname: tempLocation.pathname,
                });
                tempLocation = null;
            } else {
                // const _getProfile = async () => {
                //     const _user = await getProfile();
                //     const isDirectRoute = [
                //         location.pathname === '/jobs',
                //         location.pathname.split('/jobs/')[1],
                //         location.pathname.split('/overview/')[1],
                //         location.pathname.split('/admin/users/edit/')[1],
                //     ].some((v) => !!v);
                //     if (isDirectRoute) {
                //         return;
                //     }
                //     // if (_user.accountType === 'employer') {
                //     //     navigate('/my-jobs', { state: { authProvider: true } });
                //     // } else {
                //     //     navigate('/my-profile', { state: { authProvider: true } });
                //     // }
                // };
                // _getProfile();
            }

            sessionStorage.removeItem(LANDING_REDIRECT);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken, setAuthStatus]);

    const state: IAuth = {
        signOut,
        setToken,
        authStatus,
        user,
    };

    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
