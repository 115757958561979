import {
    Grid,
    Divider,
    FormControl,
    Autocomplete,
    Typography,
    Chip,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListSubheader,
    Checkbox,
} from '@mui/material';
import { searchAttributeModifier } from '../../../data/dropDownData';
import { AutocompleteType, LookupType, Skill } from '../../../types/skill';
import Toggle from '../../Toggle';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSkillsData } from '../../../redux/selectors/skillsSelector';
import { showWarningSnackbar } from '../../../redux/actions/snackbarAction';
import { groupBy } from '../../../utils/array';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SearchMemberModifier } from '../../../types/search';

type ComponentProps = {
    handleClose: () => void;
    handleSuccess: (data: any) => void;
    selectedDesirability: SearchMemberModifier;
    multiple?: boolean;
};

type DesiredSkill = Skill & { desirability?: SearchMemberModifier };
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SkillModal = ({ handleClose, handleSuccess, selectedDesirability = 'NICE_TO_HAVE', multiple = true }: ComponentProps) => {
    const [lookupMethod, setLookupMethod] = useState<LookupType>('KEYWORD' as LookupType);
    const [selectedSkill, setSelectedSkill] = useState<DesiredSkill>({ desirability: selectedDesirability } as DesiredSkill);
    const [selectedMultipleSkill, setSelectedMultipleSkill] = useState<DesiredSkill[]>([] as DesiredSkill[]);
    const [selected, setSelected] = useState(['', '', '']);
    const [desirability, setDesirability] = useState<SearchMemberModifier>(selectedDesirability);
    const [searchString, setSearchString] = useState<string>('');

    //redux
    const dispatch = useDispatch();
    const skills = useSelector(selectSkillsData);
    // const answers = useSelector(selectSkillsAnswers);
    const oneSkills = skills.filter((skill) => (skill.parents || []).length === 0) as any[];
    const groupByType = groupBy(['type']);
    const groupedLevelOne = groupByType(oneSkills) as Record<string, Skill[]>;
    const levelOneSkills: any[] = [];
    Object.entries(groupedLevelOne).forEach(([key, value]) => {
        levelOneSkills.push({ group: key });
        value.forEach((item) => {
            levelOneSkills.push(item);
        });
    });
    const filteredSkills = skills;
    const handleLookupChange = (_event: React.MouseEvent<HTMLElement> | null, newLookupMethod: LookupType) => {
        setLookupMethod(newLookupMethod);
        setSelectedSkill({} as DesiredSkill);
        setSelected(['', '', '']);
    };

    function selectDropdown(level: number, value: string) {
        const newArr = [...selected];
        newArr[level] = value;
        for (let i = level + 1; i < 4; i++) {
            newArr[i] = '';
        }
        setSelected(newArr);
        setSelectedSkill(skills.find((skill) => skill.id === newArr[3]) || ({} as DesiredSkill));
    }

    function getParents(item: Skill, source: Skill[]): any {
        if (item.parents && Array.isArray(item.parents) && item.parents.length > 0) {
            return item.parents
                .filter((p) => p !== item.id)
                .map((p) => {
                    const target = source.find((s) => s.id === p);
                    if (!target) {
                        return [[item.id]];
                    }
                    const parents = getParents(target, source);
                    return parents.map((newP: string[]) => [...newP, item.id]);
                })
                .flat();
        }
        return [[item.id]];
    }

    function getLevel(chain: any) {
        const level = chain?.length;
        return level;
    }

    function getSeekerHelpText(id: string, source: Skill[]) {
        const helpText = source.find((s) => s.id === id)?.seekerHelpText;
        return helpText;
    }

    function compareTitle(a: any, b: any) {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    }

    function getAutocomplete(source: Skill[]): AutocompleteType[] {
        const autoCompleteSkills = source
            .map((skill) => ({ ...skill, chain: getParents(skill, source) }))
            .map((skill: any) =>
                (skill.chain || []).map((chain: any, idx: number) => ({
                    chain,
                    id: `${skill.id}-${idx}`,
                    origId: skill.id,
                    qualFramework: skill.qualFramework,
                    expFramework: skill.expFramework,
                    keywords: skill.keywords,
                    display: skill.display,
                }))
            )
            .flat()
            .map((flatSkill) => ({
                ...flatSkill,
                id: flatSkill.origId,
                chain: undefined,
                title: flatSkill.display,
                lastChainElement: flatSkill.chain[flatSkill.chain.length - 1],
                level: getLevel(flatSkill.chain),
                seekerHelpText: getSeekerHelpText(flatSkill.origId, source),
            }))
            .filter((s) => s.level === 4);

        const uniqueAC = autoCompleteSkills.filter(
            (thing, index, self) =>
                index ===
                self.findIndex(
                    (t) =>
                        //unique on id
                        t.id === thing.id
                )
        );
        return uniqueAC.sort(compareTitle);
    }

    const handleAutoCompleteChange = (value: AutocompleteType | null) => {
        const skillId = value?.lastChainElement;

        if (!skillId) {
            setSelectedSkill({} as DesiredSkill);
            return;
        }

        const selectedAutocompleteSkill = getAutocomplete(skills).find((skill) => skill.id === skillId);
        setSelectedSkill({ ...selectedSkill, ...(selectedAutocompleteSkill as unknown as Skill) });
    };

    const handleMultipleAutoCompleteChange = (value: AutocompleteType[]) => {
        setSelectedMultipleSkill(value as unknown as DesiredSkill[]);
    };

    const getKeywordsString = (keywords?: string[]) => {
        return keywords?.length ? `(${keywords.join(',')})` : '';
    };

    const handleDesirabilityChange = (event: any) => {
        if (multiple) {
            const { value } = event.target;
            setSelectedMultipleSkill(selectedMultipleSkill.map((skill) => ({ ...skill, desirability: value })));
        }
        const { value } = event.target;
        setSelectedSkill({ ...selectedSkill, desirability: value });
        setDesirability(value);
    };

    const handleAddSkill = () => {
        if (!desirability) {
            dispatch(showWarningSnackbar('Please Select Desirability'));
            return;
        }
        if (!multiple || lookupMethod === 'HIERARCHY') {
            selectedSkill.desirability = desirability;
            if (!selectedSkill.display) {
                dispatch(showWarningSnackbar('Please Select Skill'));
                return;
            }
            handleSuccess(selectedSkill);
        } else {
            if (selectedMultipleSkill.length === 0) {
                dispatch(showWarningSnackbar('Please Select Skill'));
                return;
            }
            const values = selectedMultipleSkill.map((item) => {
                return { ...item, desirability };
            });
            handleSuccess(values);
        }
    };
    return (
        <Dialog open={true} maxWidth={'md'} fullWidth keepMounted className="prose">
            <DialogTitle style={{ textAlign: 'center' }}>Add Skill OR Experience</DialogTitle>
            <Divider></Divider>
            <DialogContent>
                <DialogContent>
                    <Grid sx={{ flexGrow: 1 }} container spacing={2} justifyContent={'center'} alignContent={'center'}>
                        <>
                            <Grid item xs={12} p={0} style={{ display: 'grid', placeItems: 'center' }}>
                                <div style={{ width: 500 }}>
                                    <Toggle
                                        value={lookupMethod}
                                        options={[
                                            {
                                                value: 'KEYWORD',
                                                label: 'FIND BY KEYWORD',
                                            },
                                            {
                                                value: 'HIERARCHY',
                                                label: 'FIND WITHIN HIERARCHY',
                                            },
                                        ]}
                                        onValueChange={(newValue) => {
                                            handleLookupChange(null, newValue as LookupType);
                                        }}
                                    />
                                </div>
                                <br />
                            </Grid>
                            <Grid item xs={12} p={0}>
                                <Divider></Divider>
                            </Grid>
                        </>

                        {lookupMethod === 'HIERARCHY' && (
                            <>
                                <Grid item style={{ width: '20%' }}>
                                    <FormControl fullWidth sx={{ m: 0, minWidth: 120, maxWidth: 300 }}>
                                        <InputLabel variant="outlined" htmlFor="select-industry">
                                            Level 1
                                        </InputLabel>
                                        <Select
                                            label="Industry"
                                            name="industry"
                                            onChange={(event) => selectDropdown(0, event.target.value)}
                                            value={selected[0]}
                                            inputProps={{ id: 'select-industry' }}
                                        >
                                            {levelOneSkills.map((item) => {
                                                if (item.group) {
                                                    return (
                                                        <ListSubheader
                                                            color="primary"
                                                            key={item.group}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                backgroundColor: '#1b717a',
                                                                color: 'white',
                                                                pointerEvents: 'none',
                                                            }}
                                                        >
                                                            <Typography variant="body1">{item.group}</Typography>
                                                        </ListSubheader>
                                                    );
                                                } else {
                                                    return (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.display}
                                                        </MenuItem>
                                                    );
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ width: '20%' }}>
                                    {selected[0] && (
                                        <FormControl fullWidth sx={{ m: 0, minWidth: 120, maxWidth: 300 }}>
                                            <InputLabel variant="outlined" htmlFor="select-category">
                                                Level 2
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                label="Category"
                                                name="category"
                                                onChange={(event) => selectDropdown(1, event.target.value)}
                                                value={selected[1]}
                                                inputProps={{ id: 'select-category' }}
                                            >
                                                {skills
                                                    .filter((skill) => skill.parents?.includes(selected[0]))
                                                    .map((record, idx) => (
                                                        <MenuItem key={idx} value={record.id}>
                                                            {record.display}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                                <Grid item style={{ width: '20%' }}>
                                    {selected[1] && (
                                        <FormControl fullWidth sx={{ m: 0, minWidth: 120, maxWidth: 300 }}>
                                            <InputLabel variant="outlined" htmlFor="select-subcategory">
                                                Level 3
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                label="Sub-category"
                                                name="subcategory"
                                                onChange={(event) => selectDropdown(2, event.target.value)}
                                                value={selected[2]}
                                                inputProps={{ id: 'select-subcategory' }}
                                            >
                                                {skills
                                                    .filter((skill) => skill.parents?.includes(selected[1]))
                                                    .map((record, idx) => (
                                                        <MenuItem key={idx} value={record.id}>
                                                            {record.display}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                                <Grid item style={{ width: '20%' }}>
                                    {selected[2] && (
                                        <FormControl fullWidth sx={{ m: 0, minWidth: 120, maxWidth: 300 }}>
                                            <InputLabel variant="outlined" htmlFor="select-skillset">
                                                SKILL or EXPERIENCE
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                label="SKILL or EXPERIENCE"
                                                name="skillset"
                                                onChange={(event) => selectDropdown(3, event.target.value)}
                                                value={selected[3]}
                                                inputProps={{ id: 'select-skillset' }}
                                            >
                                                {filteredSkills
                                                    .filter((skill) => skill.parents?.includes(selected[2]))
                                                    .map((record, idx) => (
                                                        <MenuItem key={idx} value={record.id}>
                                                            {record.display}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                            </>
                        )}

                        {lookupMethod === 'KEYWORD' && (
                            <>
                                {multiple && (
                                    <Grid item style={{ width: '80%' }}>
                                        <FormControl component="fieldset" fullWidth>
                                            <Autocomplete
                                                loadingText="Type a keyword to find associated skills & experiences"
                                                loading={!searchString?.trim()}
                                                multiple
                                                id="auto-complete"
                                                options={getAutocomplete(skills)}
                                                getOptionLabel={(option: AutocompleteType) => `${option.title} ${getKeywordsString(option.keywords)}`}
                                                autoComplete={true}
                                                onChange={(event, value) => {
                                                    handleMultipleAutoCompleteChange(value);
                                                }}
                                                filterOptions={
                                                    searchString?.trim()
                                                        ? undefined
                                                        : () => {
                                                              return [];
                                                          }
                                                }
                                                disableCloseOnSelect
                                                includeInputInList
                                                inputValue={searchString}
                                                onInputChange={(event, value) => {
                                                    setSearchString(value);
                                                }}
                                                clearOnBlur={false}
                                                isOptionEqualToValue={(option, value) => {
                                                    return option.id === value.id;
                                                }}
                                                renderOption={(props, option, { selected }) => {
                                                    return (
                                                        <li
                                                            {...props}
                                                            key={option.id}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                minHeight: 36,
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                checked={selected}
                                                                style={{ marginRight: 8, marginBottom: 4 }}
                                                            />
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    flexWrap: 'wrap',
                                                                    rowGap: '0.25rem',
                                                                    columnGap: '0.25rem',
                                                                }}
                                                            >
                                                                <Typography style={{ fontSize: '1rem', marginRight: 16 }}>{option.title}</Typography>
                                                                {/* {option.keywords.map((item: any, i: number) => (
                                                                    <Chip
                                                                        key={i}
                                                                        label={`${item}`}
                                                                        style={{ padding: 0, height: '1.5rem', pointerEvents: 'none' }}
                                                                    />
                                                                ))} */}
                                                            </div>
                                                        </li>
                                                    );
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <TextField
                                                            {...params}
                                                            variant="filled"
                                                            sx={{ m: 0, minWidth: 250 }}
                                                            autoFocus
                                                            label="Skill or Experience"
                                                        />
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                )}
                                {!multiple && (
                                    <Grid item style={{ width: '80%' }}>
                                        <FormControl component="fieldset" fullWidth>
                                            <Autocomplete
                                                loadingText="Type a keyword to find associated skills & experiences"
                                                loading={!searchString?.trim()}
                                                inputValue={searchString}
                                                filterOptions={
                                                    searchString?.trim()
                                                        ? undefined
                                                        : () => {
                                                              return [];
                                                          }
                                                }
                                                onInputChange={(event, value) => {
                                                    setSearchString(value);
                                                }}
                                                id="auto-complete"
                                                options={getAutocomplete(filteredSkills)}
                                                getOptionLabel={(option: AutocompleteType) => `${option.title} ${getKeywordsString(option.keywords)}`}
                                                renderOption={(props, option) => (
                                                    <li
                                                        {...props}
                                                        key={option.id}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            minHeight: 36,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                flexWrap: 'wrap',
                                                                rowGap: '0.25rem',
                                                                columnGap: '0.25rem',
                                                            }}
                                                        >
                                                            <Typography style={{ fontSize: '1rem', marginRight: 16 }}>{option.title}</Typography>
                                                            {option.keywords.map((item: any, i: number) => (
                                                                <Chip
                                                                    key={i}
                                                                    label={`${item}`}
                                                                    style={{
                                                                        padding: 0,
                                                                        height: '1.5rem',
                                                                        pointerEvents: 'none',
                                                                    }}
                                                                />
                                                            ))}
                                                        </div>
                                                    </li>
                                                )}
                                                autoComplete={true}
                                                includeInputInList
                                                onChange={(event, value) => {
                                                    handleAutoCompleteChange(value);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label='Search Criteria'
                                                        variant="filled"
                                                        sx={{ m: 0, minWidth: 250 }}
                                                        autoFocus
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                )}
                            </>
                        )}

                        <Grid
                            item
                            style={{
                                width: '20%',
                                marginTop: 'auto',
                            }}
                        >
                            <FormControl component="fieldset" fullWidth>
                                <InputLabel variant="filled" htmlFor="select-modifier">
                                    Desirability
                                </InputLabel>
                                <Select
                                    name="modifier"
                                    label="Desirability"
                                    onChange={handleDesirabilityChange}
                                    variant="filled"
                                    value={desirability}
                                    className="skillsDropdown"
                                    inputProps={{ id: 'select-modifier' }}
                                    disabled={!selectedSkill}
                                >
                                    {searchAttributeModifier.map((record, idx) => (
                                        <MenuItem key={idx} value={record.value}>
                                            {record.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" onClick={handleAddSkill}>
                    Add Skill
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SkillModal;
