import { AppDispatch, RootState } from '../store';
import { doSearch } from '../../services';
import { showErrorSnackbar } from './snackbarAction';
import { ProfileResult, SearchRequest } from '../../types/search';

declare type GetState = () => RootState;

export const setPublicSearchCriteria = (criteria: SearchRequest) => async (dispatch: AppDispatch, getState: GetState) => {
    try {
        dispatch({
            type: 'SET_PUBLIC_SEARCH_CRITERIA',
            payload: criteria,
        });
        await doPublicCandidateSearch()(dispatch, getState);
    } catch (e: any) {
        dispatch({
            type: 'SET_PUBLIC_SEARCH_CRITERIA_ERROR',
            payload: e?.message,
        });
    }
};

export const doPublicCandidateSearch = () => async (dispatch: AppDispatch, getState: GetState) => {
    try {
        const searchCriteria = getState().publicSearch.searchCriteria;
        const _searchResults = await doSearch(searchCriteria);
        dispatch({
            type: 'SET_PUBLIC_SEARCH_RESULTS',
            payload: _searchResults,
        });
    } catch (e: any) {
        dispatch(showErrorSnackbar(`An error occurred while loading the search results`));
    }
};

export const clearPublicSearch = () => (dispatch: AppDispatch) => {
    try {
        dispatch({
            type: 'SET_PUBLIC_SEARCH_RESULTS',
            payload: {
                results: [] as ProfileResult[],
            },
        });
        dispatch({
            type: 'SET_PUBLIC_SEARCH_CRITERIA',
            payload: {} as SearchRequest,
        });
    } catch (e: any) {
        dispatch(showErrorSnackbar(`An error occurred while loading the search results`));
    }
};
