import { RootState } from '../store';

export const selectShortlistData = (state: RootState) => state.shortlist.shortlistData;
export const selectSharedShortlistData = (state: RootState) => state.shortlist.sharedShortlistData;
export const selectShortlist = (state: RootState) => {
    return state.shortlist.shortlist;
};
export const selectSharedShortlist = (state: RootState) => state.shortlist.sharedShortlist;
export const selectCurrentShortlist = (state: RootState) =>
    (state.shortlist.shortlistData || []).find((shortlist) => shortlist.uuid === state.shortlist.shortlist?.uuid);
