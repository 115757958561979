interface SnackbarState {
    snackbarSeverity: string;
    snackbarOpen: boolean;
    snackbarMessage: string;
}

const initialState: SnackbarState = {
    snackbarSeverity: 'info',
    snackbarOpen: false,
    snackbarMessage: '',
};

const snackbarReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SNACKBAR_ERROR':
            return {
                ...state,
                snackbarSeverity: 'error',
                snackbarOpen: true,
                snackbarMessage: action.message,
            };
        case 'SNACKBAR_WARNING':
            return {
                ...state,
                snackbarSeverity: 'warning',
                snackbarOpen: true,
                snackbarMessage: action.message,
            };
        case 'SNACKBAR_INFO':
            return {
                ...state,
                snackbarSeverity: 'info',
                snackbarOpen: true,
                snackbarMessage: action.message,
            };
        case 'SNACKBAR_SUCCESS':
            return {
                ...state,
                snackbarSeverity: 'success',
                snackbarOpen: true,
                snackbarMessage: action.message,
            };
        case 'SNACKBAR_CLEAR':
            return {
                ...state,
                snackbarOpen: false,
                snackbarSeverity: 'info',
                snackbarMessage: '',
            };
        default:
            return state;
    }
};

export default snackbarReducer;
