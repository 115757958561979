import { configureStore, ThunkAction, Action, Dispatch } from '@reduxjs/toolkit';
import geographyReducer from './reducers/geographyReducer';
import skillsReducer from './reducers/skillsReducer';
import searchReducer from './reducers/searchReducer';
import profileReducer from './reducers/profileReducer';
import shortlistReducer from './reducers/shortlistReducer';
import filesReducer from './reducers/filesReducer';
import organisationsReducer from './reducers/organisationsReducer';
import snackbarReducer from './reducers/snackbarReducer';
import userReducer from './reducers/userReducer';
import adminReducer from './reducers/adminReducer';
import profileCommentsReducer from './reducers/profileComments';
import JobReducer from './reducers/jobReducer';
import publicSearchReducer from './reducers/publicSearchReducer';

const store = configureStore({
    reducer: {
        admin: adminReducer,
        geography: geographyReducer,
        skills: skillsReducer,
        search: searchReducer,
        profile: profileReducer,
        shortlist: shortlistReducer,
        files: filesReducer,
        organisations: organisationsReducer,
        snackbarAlert: snackbarReducer,
        user: userReducer,
        profileComments: profileCommentsReducer,
        job: JobReducer,
        publicSearch: publicSearchReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = Dispatch<any>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
