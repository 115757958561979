import { Organisation } from '../../types/organisation';

interface OrganisationsState {
    organisationsData: Organisation[];
    loading: boolean;
    userOrganization: Organisation;
}

const initialState: OrganisationsState = {
    organisationsData: [],
    loading: false,
    userOrganization: {} as Organisation,
};

const organisationsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'GET_ORGANISATIONS':
            return {
                ...state,
                organisationsData: action.payload,
                loading: false,
            } as OrganisationsState;
        case 'SKILLS_SET_LOADING':
            return {
                ...state,
                loading: action.payload,
            } as OrganisationsState;
        case 'SET_USER_ORGANIZATION':
            return {
                ...state,
                userOrganization: action.payload,
            } as OrganisationsState;
        default:
            return state as OrganisationsState;
    }
};

export default organisationsReducer;
