import { Typography, Link as StyledLink, Grid, Button, ButtonGroup } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { getInstance } from '../../../../services/fetch';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganisations } from '../../../../redux/actions/organisationsAction';
import { selectOrganisationsData } from '../../../../redux/selectors/organisationsSelector';
import { ViewAsColumnJob } from '../../../../components/ViewAsColumnJob';
import { configContextObject } from '../../../../config/ConfigProvider';
import { ListPaginationDefault } from '../../../../constant';

enum ViewAs {
    CARD = 'CARD',
    TABLE = 'TABLE',
}

const JobBoard: React.FC = () => {
    const { config } = useContext(configContextObject);
    const dispatch = useDispatch();
    const organizations = useSelector(selectOrganisationsData);
    const [pageSize, setPageSize] = useState<number>(ListPaginationDefault);

    const [viewAs, setViewAs] = React.useState<ViewAs>(ViewAs.CARD);

    const handlePageSizeChange = (size: number) => {
        setPageSize(size);
    };

    useEffect(() => {
        dispatch(getOrganisations());
    }, [dispatch]);
    const JobQuery = useQuery(
        ['job'],
        async () => {
            const response = await getInstance().get<{ data: any; error: string }>(`/public/job`);
            if (response.error) {
                throw new Error(response.error);
            }
            return response.data;
        },
        {
            cacheTime: 1000,
            staleTime: 1000,
        }
    );

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 1,
            renderCell: (params: any) => (
                <a href={`${config.serverUri}/jobs/${params.row.uuid}`} target="_blank" rel="noreferrer">
                    <StyledLink component="span">{params.row.id}</StyledLink>
                </a>
            ),
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
        },
        {
            field: 'organization',
            headerName: 'Organization',
            renderCell: (params: any) => <Typography>{(organizations || [])?.find((item) => item.id === params.row.organization)?.name}</Typography>,
            flex: 1,
        },
        {
            field: 'city',
            headerName: 'City',
            flex: 1,
        },
        {
            field: 'country',
            headerName: 'Country',
            flex: 1,
        },
        {
            field: 'minSecClearance',
            headerName: 'Min. Sec. Clearance',
            flex: 1,
            renderCell: (params: any) => {
                const securityClearance = params.row.securityClearance || [];
                if (Array.isArray(securityClearance)) {
                    return securityClearance?.map((item: string) => <Typography key={item}>{item}</Typography>);
                } else {
                    return <Typography>{securityClearance}</Typography>;
                }
            },
        },
    ];

    return (
        <>
            <Grid container display={'flex'} justifyContent={'space-around'} mt={'1rem'}>
                {/* <Grid item xs={3} mt={'3rem'}>
                    <Typography variant="h6">Filter by:</Typography>
                    <Typography variant="body1">City</Typography>
                    <Typography variant="body1">Country</Typography>
                    <Typography variant="body1">Organization</Typography>
                    <Typography variant="body1">Security Clearance</Typography>
                </Grid> */}
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={3}>
                            <div style={{ display: 'flex' }}>
                                <ButtonGroup variant="outlined" fullWidth>
                                    <Button variant={viewAs === ViewAs.CARD ? 'contained' : 'outlined'} onClick={() => setViewAs(ViewAs.CARD)}>
                                        <i className="fa-solid fa-table-cells mr-1"></i>Card View
                                    </Button>
                                    <Button variant={viewAs === ViewAs.TABLE ? 'contained' : 'outlined'} onClick={() => setViewAs(ViewAs.TABLE)}>
                                        <i className="fa-solid fa-list mr-1"></i>
                                        List View
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </Grid>
                        <Grid item xs={0} md={0} lg={9}></Grid>
                    </Grid>
                    {viewAs === ViewAs.TABLE && (
                        <Grid item xs={12} mt={'2rem'}>
                            <DataGrid
                                style={{
                                    minHeight: 500,
                                }}
                                autoHeight
                                rows={JobQuery.data || []}
                                columns={columns}
                                rowHeight={38}
                                disableSelectionOnClick
                                rowsPerPageOptions={[15, 25, 50, 100]}
                                pageSize={pageSize}
                                onPageSizeChange={handlePageSizeChange}
                            />
                        </Grid>
                    )}
                    {viewAs === ViewAs.CARD && <ViewAsColumnJob rows={JobQuery.data || []} />}
                </Grid>
            </Grid>
        </>
    );
};

export default JobBoard;
